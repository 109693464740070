.loading_in_app{
    min-height: 100vh;
    width: 100%;
    position: relative;
    z-index: 998;
    background: #000;
}
.loading_in_app .loading_in_app_img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100px;
}