.mc-overlay-success{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000dd;
    z-index: 1000;
}
.mc-popup-success{
    position: fixed;
    top: 10%;
    left: 50%;
    width: 90%;
    height: auto;
    border-radius: 30px;
    border: 1px solid rgba(48, 44, 44, 0.50);
    background: rgba(34, 36, 43, 0.70);
    z-index: 1001;
    transform: translateX(-50%);
    padding: 20px;
}
.mc-popup-success img{
    width: 120px;
    margin: 0 auto;
    margin-bottom: 15px;
}
.mc-popup-success .title-pop{
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-family: "SFLight";
    margin-bottom: 20px;
}
.mc-popup-success .inp-key input{
    display: block;
    width: 100%;
    border: 0;
    padding: 10px 8px;
    outline: none;
    font-size: 16px;
}
.mc-popup-success .inp-key{
    margin-bottom: 20px;
}
.mc-popup-success .oke{
    display: block;
    width: 100%;
    padding: 10px 8px;
    border: 0;
    background: #11af11;
    text-align: center;
    color: #fff;
    outline: none;
    font-size: 16px;
    border-radius: 8px;
    margin-bottom: 15px;
}
.mc-popup-success .verify:hover{
    opacity: 0.7;
}
.mc-popup-success .close-ve{
    display: block;
    width: 100%;
    padding: 10px 8px;
    border: 0;
    background: #c51919;
    text-align: center;
    color: #fff;
    outline: none;
    font-size: 16px;
    border-radius: 8px;
    margin-bottom: 15px;
}
.mc-popup-success .close-ve:hover{
    opacity: 0.7;
}