.rank_dg{
    background-image: url("../../assets/images/hard/bg-store.png");
    min-height: 100vh;
    width: 100%;
    background-position: bottom, center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 30px;
    padding-bottom: 120px;
}
.rank_dg .title-rank{
    color: #FFF;
    font-size: 24px;
    font-family: "SFBold";
    text-align: center;
    padding-top: 20px;
    margin-bottom: 15px;
}
.rank_dg .top-rank{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow-x: hidden;
}
.rank_dg .top-rank .item{
    flex: 0 0 33.33%;
    max-width: 32%;
    width: 100%;
}
.rank_dg .top-rank .item.item-rank-2{
    padding-top: 60px;
}
.rank_dg .top-rank .item.item-rank-1{
    padding-top: 15px;
}
.rank_dg .top-rank .item.item-rank-3{
    padding-top: 80px;
}
.rank_dg .top-rank .item .avatar{
    width: 63px;
    margin: 0 auto;
    margin-bottom: 15px;
    position: relative;
}
.rank_dg .top-rank .item .avatar img{
    height: 63px;
    width: 63px;
    border-radius: 50%;
    border: 2px solid #FFD337;
    object-fit: cover;
}
.rank_dg .top-rank .item .avatar .vip{
    position: absolute;
    width: 30px;
    top: -15px;
    right: -5px;
}
.rank_dg .top-rank .item .avatar .vip img{
    height: auto;
    width: 30px;
    border-radius: 0;
    border: 0;
}
.rank_dg .top-rank .item .name{
    color: #fff;
    margin-bottom: 5px;
    text-align: center;
}
.rank_dg .top-rank .item .total-coin{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}
.rank_dg .top-rank .item .total-coin img{
    width: 16px;
}
.rank_dg .top-rank .item .total-coin .txt{
    padding-left: 5px;
    color: #fff;
}
.rank_dg .ten-rank .item-ten-rank{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 16px;
    border: 1px solid rgb(255 255 255 / 31%);
    background: rgb(255 255 255 / 12%);
    background-blend-mode: overlay;
    padding: 12px;
    margin-bottom: 16px;
}
.rank_dg .ten-rank .item-ten-rank .stt{
    flex: 0 0 10%;
    max-width: 10%;
    width: 100%;
    color: #fff;
}
.rank_dg .ten-rank .item-ten-rank .avatar{
    flex: 0 0 18%;
    max-width: 18%;
    width: 100%;
}
.rank_dg .ten-rank .item-ten-rank .avatar img{
    width: 42px;
    height: 42px;
    border-radius: 50%;
    object-fit: cover;
}
.rank_dg .ten-rank .item-ten-rank .name-coin{
    flex: 0 0 72%;
    max-width: 72%;
    width: 100%;
    color: #fff;
}
.rank_dg .ten-rank .item-ten-rank .name-coin .name{
    color: #fff;
    margin-bottom: 5px;
}
.rank_dg .ten-rank .item-ten-rank .coin-total{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
}
.rank_dg .ten-rank .item-ten-rank .coin-total .icon{
    width: 16px;
    margin-right: 5px;
}
.rank_dg .ten-rank .item-ten-rank .coin-total .txt{
    font-family: "SFLight"
}
.rank_dg .ten-rank{
    padding: 25px 8px 10px 8px;
    background: #22242B;
    border-radius: 20px;
}
.rank_dg .top-rank .bes{
    height: 112px;
    overflow: hidden;
}
.rank_dg .item-ten-rank-your{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.50);
    background: #000;
    background-blend-mode: overlay;
    padding: 12px;
    margin-bottom: 16px;
    margin-top: 40px;
}

.rank_dg .item-ten-rank-your .stt{
    flex: 0 0 10%;
    max-width: 10%;
    width: 100%;
    color: #fff;
}
.rank_dg .item-ten-rank-your .avatar{
    flex: 0 0 18%;
    max-width: 18%;
    width: 100%;
}
.rank_dg .item-ten-rank-your .avatar img{
    width: 42px;
    height: 42px;
    border-radius: 50%;
    object-fit: cover;
}
.rank_dg .item-ten-rank-your .name-coin{
    flex: 0 0 72%;
    max-width: 72%;
    width: 100%;
    color: #fff;
}
.rank_dg .item-ten-rank-your .name-coin .name{
    color: #fff;
    margin-bottom: 5px;
}
.rank_dg .item-ten-rank-your .coin-total{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
}
.rank_dg .item-ten-rank-your .coin-total .icon{
    width: 16px;
    margin-right: 5px;
}
.rank_dg .item-ten-rank-your .coin-total .txt{
    font-family: "SFLight"
}
