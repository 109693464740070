.pop_earn_money_offline{
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 16px;
    width: 100%;
    height: auto;
    z-index: 1001;
}
.pop_earn_money_offline .upgrade_card_contain{
    background: rgb(52,53,58);
    background: linear-gradient(180deg, rgba(52,53,58,0.8211659663865546) 0%, rgba(52,53,58,0.6474964985994398) 35%, rgba(52,53,58,0) 100%);
    padding: 0 12px 60px 12px;
    border-radius: 30px;
}
.pop_earn_money_offline .upgrade_card_contain .dr-on-card{
    width: 160px;
    margin: 0 auto;
    margin-bottom: 16px;
}
.pop_earn_money_offline .upgrade_card_contain .title{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 24px;
    font-family: "SFBold";
    margin-bottom: 5px;
}
.pop_earn_money_offline .upgrade_card_contain .title .img-up{
    width: 20px;
    margin-right: 5px;
}
.pop_earn_money_offline .upgrade_card_contain .desc{
    font-size: 14px;
    text-align: center;
    color: #BCBCBC;
    font-family: "SFLight";
    margin-bottom: 25px;
}
.pop_earn_money_offline .upgrade_card_contain .level-contain{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 25px;
}
.pop_earn_money_offline .upgrade_card_contain .level-contain .item{
    flex: 0 0 50%;
    max-width: 46%;
    width: 100%;
}
.pop_earn_money_offline .upgrade_card_contain .level-contain .arrow-up{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
}
.pop_earn_money_offline .upgrade_card_contain .level-contain .item .item-contain .level{
    color: #fff;
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
}
.pop_earn_money_offline .upgrade_card_contain .exchange{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 46px;
}
.pop_earn_money_offline .upgrade_card_contain .exchange .ex-item{
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    padding: 12px 12px;
    border-radius: 16px;
    border:1px solid #ffffff59;
}
.pop_earn_money_offline .upgrade_card_contain .exchange .ex-item.ex-item-contain-color{
    background:linear-gradient(180deg, #2874FC -69.3%, #020381 169.3%);
}
.pop_earn_money_offline .upgrade_card_contain .exchange .ex-item .ex-txt{
    color: #fff;
    margin-bottom: 10px;
    text-align: center;
}
.pop_earn_money_offline .upgrade_card_contain .exchange .ex-item .card-ex-cover{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}
.pop_earn_money_offline .upgrade_card_contain .exchange .ex-item .card-ex-cover .card{
    width: 24px;
}
.pop_earn_money_offline .upgrade_card_contain .exchange .ex-item .card-ex-cover .numb{
    color: #FFD337;
    text-align: center;
    font-size: 18px;
    font-family: "SFBold";
    padding-left: 6px;
}
.pop_earn_money_offline .upgrade_card_contain .btn-up{
    margin-bottom: 20px;
}
.pop_earn_money_offline .upgrade_card_contain .btn-up button{
    display: block;
    width: 100%;
    border-radius: 16px;
    background: #34353A;
    color: #fff;
    font-family: "SFBold";
    text-align: center;
    padding: 16px;
    font-size: 16px;
    outline: none;
    border: 0;
}
.pop_earn_money_offline .upgrade_card_contain .btn-up button:hover{
    background: linear-gradient(180deg, #2874FC -69.3%, #020381 169.3%);
}
.pop_earn_money_offline .upgrade_card_contain .btn-close-up button{
    display: block;
    width: 100%;
    border-radius: 16px;
    background: #df2222;
    color: #fff;
    font-family: "SFBold";
    text-align: center;
    padding: 16px;
    font-size: 16px;
    outline: none;
    border: 0;
}
.pop_earn_money_offline .upgrade_card_contain .btn-close-up button:hover{
    opacity: 0.7;
}
.overlay-earn-ofline{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000ed;
    z-index: 1000;
}