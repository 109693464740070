.money-in-game{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px 0 30px 0;
}
.money-in-game .money-in-game-icon img{
    width: 30px;
}
.money-in-game .mc2_txt{
    padding-left: 8px;
    background: linear-gradient(180deg, #FFC700 35.5%, #F90 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 24px;
    font-family: "SFBold";
}
.game_dragon_king{
    background-image: url("../../assets/images/hard/game-bg.png");
    width: 100%;
    background-position: bottom, center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 30px;
    padding-bottom: 350px;
}
.game_dragon_king .game{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.game_dragon_king .game .item-game{
    flex: 0 0 25%;
    max-width: 24.5%;
    width: 100%;
    margin-bottom: 25px;
}
.game_dragon_king .game .item-game .dra{
    width: 60px;
    margin: 0 auto;
    min-height: 75px;
}
.game_dragon_king .time-game{
    width: 130px;
    position: relative;
    margin-left: auto;
}
.game_dragon_king .time-game .txt-time{
    color: #fff;
    font-family: "ClashDisplay-Regular";
    font-size: 14px;
    position: absolute;
    top: 12px;
    right: 35px;
}
.jello-horizontal{-webkit-animation:jello-horizontal .5s both;animation:jello-horizontal .5s both}
 @-webkit-keyframes jello-horizontal{0%{-webkit-transform:scale3d(1,1,1);transform:scale3d(1,1,1)}30%{-webkit-transform:scale3d(1.25,.75,1);transform:scale3d(1.25,.75,1)}40%{-webkit-transform:scale3d(.75,1.25,1);transform:scale3d(.75,1.25,1)}50%{-webkit-transform:scale3d(1.15,.85,1);transform:scale3d(1.15,.85,1)}65%{-webkit-transform:scale3d(.95,1.05,1);transform:scale3d(.95,1.05,1)}75%{-webkit-transform:scale3d(1.05,.95,1);transform:scale3d(1.05,.95,1)}100%{-webkit-transform:scale3d(1,1,1);transform:scale3d(1,1,1)}}@keyframes jello-horizontal{0%{-webkit-transform:scale3d(1,1,1);transform:scale3d(1,1,1)}30%{-webkit-transform:scale3d(1.25,.75,1);transform:scale3d(1.25,.75,1)}40%{-webkit-transform:scale3d(.75,1.25,1);transform:scale3d(.75,1.25,1)}50%{-webkit-transform:scale3d(1.15,.85,1);transform:scale3d(1.15,.85,1)}65%{-webkit-transform:scale3d(.95,1.05,1);transform:scale3d(.95,1.05,1)}75%{-webkit-transform:scale3d(1.05,.95,1);transform:scale3d(1.05,.95,1)}100%{-webkit-transform:scale3d(1,1,1);transform:scale3d(1,1,1)}}


 .mc-overlay-game-finish{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000dd;
    z-index: 1000;
}
.mc-popup-game-finish{
    position: fixed;
    top: 10%;
    left: 50%;
    width: 90%;
    height: auto;
    border-radius: 30px;
    border: 1px solid rgba(48, 44, 44, 0.50);
    background: rgba(34, 36, 43, 0.70);
    z-index: 1001;
    transform: translateX(-50%);
    padding: 20px;
}
.mc-popup-game-finish img{
    width: 120px;
    margin: 0 auto;
    margin-bottom: 15px;
}
.mc-popup-game-finish .title-pop{
    color: #ff0000;
    text-align: center;
    font-size: 20px;
    font-family: "SFBold";
    margin-bottom: 15px;
    text-transform: uppercase;
}
.mc-popup-game-finish .money-earn{
    text-align: center;
    font-size: 24px;
    margin-bottom: 15px;
    background: linear-gradient(180deg, #FFC700 35.5%, #F90 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 24px;
}
.mc-popup-game-finish .inp-key input{
    display: block;
    width: 100%;
    border: 0;
    padding: 10px 8px;
    outline: none;
    font-size: 16px;
}
.mc-popup-game-finish .inp-key{
    margin-bottom: 20px;
}
.mc-popup-game-finish .oke{
    display: block;
    width: 100%;
    padding: 10px 8px;
    border: 0;
    background: #11af11;
    text-align: center;
    color: #fff;
    outline: none;
    font-size: 16px;
    border-radius: 8px;
    margin-bottom: 15px;
}
.mc-popup-game-finish .verify:hover{
    opacity: 0.7;
}
.mc-popup-game-finish .close-ve{
    display: block;
    width: 100%;
    padding: 10px 8px;
    border: 0;
    background: #c51919;
    text-align: center;
    color: #fff;
    outline: none;
    font-size: 16px;
    border-radius: 8px;
    margin-bottom: 15px;
}
.mc-popup-game-finish .close-ve:hover{
    opacity: 0.7;
}