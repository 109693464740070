.mc-store{
    background-image: url("../../assets/images/hard/bg-store.png");
    width: 100%;
    background-position: bottom, center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 30px;
    padding-bottom: 100px;
    position: relative;
}
.mc-store .title{
    color: #FFF;
    font-family: "SFBold";
    font-size: 24px;
    margin-bottom: 25px;
}
.mc-store .money .txt{
    color: #fff;
    margin-bottom: 5px;
    font-size: 14px;
}
.mc-store .user-and-money{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 40px;
}
.mc-store .user-and-money .user{
    width: 50px;
    padding: 5px;
    border: 1px solid #FFC700;
    border-radius: 50%;
}
.mc-store .user-and-money .user img{
    border-radius: 50%;
}
.mc-store .user-and-money .money {
    padding-left: 15px;
}
.mc-store .user-and-money .money img{
    width: 20px;
}
.mc-store .user-and-money .money .ic-coin{
    margin-bottom: 2px;
}
.mc-store .user-and-money .money .currency{
    background: linear-gradient(180deg, #FFC700 35.5%, #F90 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
     font-family: "SFBold";
}
.mc-store .store-cover .store-cat{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.mc-store .store-cover .store-cat .item{
    color: #a5a5a5;
    font-size: 16px;
    font-family: "SFMedium";
    padding: 10px 14px;
    
}
.mc-store .store-cover .store-cat .item.active{
    color: #FFF;
    border-bottom: 1px solid #fff;
}
.mc-store .list-card{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-height: calc(90vh - 280px);
    overflow-y: scroll;
    padding-bottom: 35px;
    position: relative;
    min-height: 420px;
}
.mc-store .list-card .item-card{
    flex: 0 0 50%;
    max-width: 48%;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 15px;
    position: relative;
}

.list-card .item-card .item-card-bottom{
    padding: 8px 12px 10px 12px;
}
.list-card .item-card .item-card-bottom .buy-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 8px;
    border: 0.5px solid #6E6E71;
    color: #fff;
    width: 100%;
    padding: 6px 5px 5px 5px;
    font-size: 12px;
}
.list-card .item-card .item-card-bottom .buy-btn img{
    width: 20px;
    padding-left: 4px;
}
.mc-store .dra-sty-bonus{
    position: absolute;
    top: 45px;
    right: 20px;
    width: 100px;
}
.list-card .item-card .child-on-card{
    position: absolute;
    top: 50%;
    width: 80%;
    height: auto;
    max-height: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}
.list-card .item-card .child-on-card .dra{
    margin-bottom: 10px;
}
.list-card .item-card .child-on-card .dra img{
    height: 74px;
    object-fit: contain;
    margin: 0 auto;
}
.list-card .item-card .child-on-card .name-card{
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
}
.list-card .item-card .child-on-card .earn-per-hour{
    font-size: 12px;
    color: #909090;
    text-align: center;
    margin-bottom: 10px;
}
.list-card .item-card .child-on-card .txt-per{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    color: #fff;
}
.list-card .item-card .child-on-card .txt-per img{
    width: 16px;
}
.list-card .item-card .child-on-card .txt-per .num{
    font-size: 14px;
    padding-left: 5px;
}
.list-card .item-card .item-card-top{
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid #80808085;
}
.overlay-loading{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000086;
    z-index: 1;
}
.mc-loading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 150px;
    z-index: 2;
}
.txt-no-card{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    color: #fff;
}
