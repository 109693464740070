.task_dragon_king{
    background-image: url("../../assets/images/hard/Task.png");
    width: 100%;
    background-position: bottom, center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 30px;
    padding-bottom: 120px;
}
.task_dragon_king .title{
    color: #FFF;
    font-size: 24px;
    margin-bottom: 16px;
    font-family: "SFBold";
    text-align: center;
    padding-top: 20px;
}
.task_dragon_king .list-task .list-taks-title{
    color: #FFF;
    font-size: 18px;
    margin-bottom: 16px;
    font-family: "SFBold";
}
.task_dragon_king .list-task .list-task-cover .item{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px;
    border-radius: 16px;
    border: 1px solid rgb(255 255 255 / 22%);
    background: rgb(255 255 255 / 6%);
    background-blend-mode: overlay;
    margin-bottom: 12px;
}
.task_dragon_king .list-task{
    margin-bottom: 20px;
}
.task_dragon_king .list-task .list-task-cover .item .icon{
    flex: 0 0 16%;
    max-width: 15%;
    width: 100%;
}
.task_dragon_king .list-task .list-task-cover .item .st-btn-action img{
    width: 30px;
    margin-left: auto;
    padding-right: 10px;
}
.task_dragon_king .list-task .list-task-cover .item .st-btn-action{
    flex: 0 0 20%;
    max-width: 19%;
    width: 100%;
}
.task_dragon_king .list-task .list-task-cover .item .name-reward{
    flex: 0 0 64%;
    max-width: 63%;
    width: 100%;
}
.task_dragon_king .list-task .list-task-cover .item .icon img{
    width: 44px;
}
.task_dragon_king .list-task .list-task-cover .item .name-reward .txt{
    color: #fff;
    margin-bottom: 5px;
}
.task_dragon_king .list-task .list-task-cover .item .name-reward .nr-img-price{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
}
.task_dragon_king .list-task .list-task-cover .item .name-reward .nr-img-price img{
    width: 16px;
}
.task_dragon_king .list-task .list-task-cover .item .name-reward .nr-img-price .pri{
    color: #fff;
    padding-left: 5px;
    font-size: 14px;
    font-family: "SFLight";
}
.task_dragon_king .list-task .st-btn-action button{
    padding: 6px 10px;
    border: 0;
    border-radius: 4px;
    background: rgba(196, 196, 196, 0.10);
    outline: none;
    color: #fff;
    margin-left: auto;
    display: block;
}
.task_dragon_king .list-task .st-btn-action button.claim{
    background: linear-gradient(180deg, #2874FC -69.3%, #020381 169.3%);
}
.overlay-task{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000dd;
    z-index: 1000;
}
.popup-verify-task{
    position: fixed;
    top: 10%;
    left: 50%;
    width: 90%;
    height: auto;
    border-radius: 30px;
    border: 1px solid rgba(48, 44, 44, 0.50);
    background: rgba(34, 36, 43, 0.70);
    z-index: 1001;
    transform: translateX(-50%);
    padding: 20px;
}
.popup-verify-task img{
    width: 120px;
    margin: 0 auto;
    margin-bottom: 15px;
}
.popup-verify-task .title-pop{
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-family: "SFBold";
    margin-bottom: 10px;
}
.popup-verify-task .inp-key input{
    display: block;
    width: 100%;
    border: 0;
    padding: 10px 8px;
    outline: none;
    font-size: 16px;
}
.popup-verify-task .inp-key{
    margin-bottom: 20px;
}
.popup-verify-task .verify{
    display: block;
    width: 100%;
    padding: 10px 8px;
    border: 0;
    background: #11af11;
    text-align: center;
    color: #fff;
    outline: none;
    font-size: 16px;
    border-radius: 8px;
    margin-bottom: 15px;
}
.popup-verify-task .verify:hover{
    opacity: 0.7;
}
.popup-verify-task .close-ve{
    display: block;
    width: 100%;
    padding: 10px 8px;
    border: 0;
    background: #c51919;
    text-align: center;
    color: #fff;
    outline: none;
    font-size: 16px;
    border-radius: 8px;
    margin-bottom: 15px;
}
.popup-verify-task .close-ve:hover{
    opacity: 0.7;
}
.popup-verify-task .key-fail{
    color: #c51919;
    font-size: 16px;
    margin-bottom: 20px;
    text-align: center;
}