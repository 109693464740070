.invite-friend{
    min-height: 100vh;
    width: 100%;
    background: linear-gradient(180deg, #1F2228 0%, #16191D 100%);
    padding-top: 30px;
    padding-bottom: 100px;
}
.invite-friend .title{
    color: #FFF;
    font-family: "SFBold";
    font-size: 26px;
    margin-bottom: 10px;
    padding-top: 25px;
}
.invite-friend .desc{
    color: #FFF;
    font-size: 16px;
    margin-bottom: 15px;
}
.invite-friend .in-frie{
    padding: 15px;
    border-radius: 20px;
    border: 1px solid rgb(255 255 255 / 22%);
    background: rgb(255 255 255 / 6%);
    background-blend-mode: overlay;
    margin-bottom: 20px;
}
.invite-friend .in-frie .icon{
    width: 42px;
    margin-bottom: 10px;
}
.invite-friend .in-frie .txt{
    color: #fff;
    font-size: 18px;
    margin-bottom: 10px;
}
.invite-friend .in-frie .get-coin{
    color: #fff;
    font-size: 14px;
    padding: 8px 10px;
    border-radius: 4px;
    background: rgba(15, 15, 15, 0.53);
    margin-bottom: 16px;
}
.btn-add-friend{
    width: 140px;
    background: transparent;
    border: 0;
    outline: none;
}
.invite-friend .list-added-fri .laf-title{
    color: #FFF;
    font-family: "SFBold";
    font-size: 20px;
    margin-bottom: 20px;
}
.invite-friend .list-added-fri .list .item{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 16px;
    border: 1px solid rgb(255 255 255 / 22%);
    background: rgb(255 255 255 / 6%);
    background-blend-mode: overlay;
    padding: 10px 15px;
    margin-bottom: 15px;
}
.invite-friend .list-added-fri .list .item .img-fri{
    flex: 0 0 18%;
    max-width: 16%;
    width: 100%;
}
.invite-friend .list-added-fri .list .item img{
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
.invite-friend .list-added-fri .list .item .name-friend{
    color: #fff;
    flex: 0 0 82%;
    max-width: 82%;
    width: 100%;
}
.invite-friend .list-added-fri .list{
    max-height: 302px;
    overflow-y: scroll;
}
.no-friends{
    text-align: center;
    color: #fff;
    font-size: 16px;
}
.txt-copy{
    color: green;
    font-size: 16px;
    margin-top: 10px;
}