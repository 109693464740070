.airdrop_dg{
    background-image: url("../../assets/images/hard/Airdrop-1.jpg");
    width: 100%;
    background-position: bottom, center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 30px;
    padding-bottom: 320px;
}
.airdrop_dg .dg-air img{
    width: 200px;
    margin: 0 auto;
}
.airdrop_dg .dg-air{
    padding-top: 30px;
    margin-bottom: 20px;
}
.airdrop_dg .air-title{
    text-align: center;
    color: #fff;
    font-size: 24px;
    margin-bottom: 10px;
    font-family: "SFBold";
}
.airdrop_dg .air-desc{
    color: #C3C3C3;
    text-align: center;
    font-size: 15px;
    font-family: "SFLight";
    line-height: 24px;
    margin-bottom: 20px;
}
.airdrop_dg .task-lists button{
    width: 100%;
    background: transparent;
    outline: none;
    border: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.50);
    background: linear-gradient(180deg, #2874FC -69.3%, #020381 169.3%);
    background-blend-mode: overlay;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    padding: 10px 12px;
    text-align: left;
    color: #fff;
    font-size: 16px;
    margin-bottom: 14px;
}
.airdrop_dg .task-lists button .icon{
    flex: 0 0 18%;
    max-width: 16%;
    width: 100%;
}
.airdrop_dg .task-lists button .icon img{
    width: 40px;
}
.airdrop_dg .task-lists button .txt{
    flex: 0 0 77%;
    max-width: 75%;
    width: 100%;
}
.airdrop_dg .task-lists button .arrow{
    flex: 0 0 5%;
    max-width: 5%;
    width: 100%;
}
.airdrop_dg .task-lists button.item-disable{
    opacity: 0.4;
}
.airdrop_dg .task-lists-titl{
    color: #FFF;
    font-size: 16px;
    font-family: "SFBold";
    margin-bottom: 16px;
}
.task-list-cover .txt-bot-connect{
    margin: 10px 0 20px 0;
    color: #ffffffbb;
    font-size: 14px;
    text-align: center;
}
.btn-con{
    width: max-content;
    margin: 0 auto;
}
.task-list-cover .txt-bot-connect-ok{
    margin: 10px 0 20px 0;
    color: green;
    font-size: 14px;
    text-align: center;
}
.status-transaction{
    margin: 10px 0 20px 0;
    color: rgb(16, 167, 16);
    font-size: 14px;
    text-align: center;
}