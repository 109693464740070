.notice_when_tdesktop{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #fff;
    background: #000;
    padding: 20px;
    text-align: center;
}