.money_current{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}
.money_current .money_current_icon img{
    width: 30px;
}
.money_current .mc2_txt{
    padding-left: 8px;
    background: linear-gradient(180deg, #FFC700 35.5%, #F90 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 24px;
    font-family: "SFBold";
}