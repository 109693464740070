@font-face {
	font-family: "SFRegular";
	src: local("SFRegular"), url("./assets/fonts/SF-Pro-Display-Regular.otf");
	font-weight: 400
}
@font-face {
	font-family: "SFMedium";
	src: local("SFMedium"), url("./assets/fonts/SF-Pro-Display-Medium.otf");
	font-weight: 500
}
@font-face {
	font-family: "SFBold";
	src: local("SFBold"), url("./assets/fonts/SF-Pro-Display-Bold.otf");
	font-weight: 700
}
@font-face {
	font-family: "SFLight";
	src: local("SFLight"), url("./assets/fonts/SF-Pro-Display-Light.otf");
	font-weight: 300
}
@font-face {
	font-family: "ClashDisplay-Regular";
	src: local("ClashDisplay-Regular"), url("./assets/fonts/ClashDisplay-Regular.otf");
	font-weight: 400
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  
}
html, body {
    overflow: hidden; /* Ngăn cuộn toàn bộ màn hình */
    height: 100%;
}
html{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  padding: 0;
  margin: 0;
  font-family: "SFRegular";
  font-size: 15px;
  box-sizing: border-box;
  letter-spacing: 1px;
}
img{
	display: block;
	width: 100%;
}
a{
	text-decoration: none;
}
.mc-container-app{
	width: 100%;
	padding: 0 10px;
	margin: 0 auto;
}
.mc-money{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
}
.mc-money .mc-img-dong{
	width: 16px;
}
.mc-money .mc-mon{
	color: #fff;
	font-size: 16px;
	font-family: "SFBold";
	padding-left: 5px;
}