.user_head .us-row{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.user_head .us-row .left{
    flex: 0 0 20%;
    max-width: 16%;
    width: 100%;
}
.user_head .us-row .right{
    flex: 0 0 80%;
    max-width: 80%;
    width: 100%;
}
.user_head .us-row .left img{
    border-radius: 50%;
}
.user_head .us-row .left .left-img{
    border-radius: 50%;
    padding: 5px;
    border: 1px solid #FFC700;
}
.user_head .us-row .right .name{
    color: #fff;
    font-size: 16px;
    font-family: "SFBold";
    margin-bottom: 4px;
}
.user_head .us-row .right .moneyhour{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 4px;
}
.us-row .right .moneyhour .icon-price img{
    width: 20px;
}
.us-row .right .moneyhour .moneyhour-txt{
    padding-left: 8px;
    font-size: 12px;
    color: #fff;
    font-family: "ClashDisplay-Regular";
}
.us-row .right .level{
    color: #fff;
    font-size: 14px;
    color: #ffb51e;
    font-family: "ClashDisplay-Regular";
}