.loading_start{
    background-image: url("../../assets/images/hard/dr-load.jpg");
    min-height: 100vh;
    width: 100%;
    background-position: bottom, center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.loading_start .loading-bar{
    position: absolute;
    bottom: 21%;
    left: 50%;
    transform: translateX(-50%);
    height: 5px;
    width: 80%;
    background: #8080808c;
    box-shadow: 1px 1px 9px #0000ffab;
}
.loading_start .loading-bar .load-roll-cover{
    position: relative;
    width: 100%;
    height: 100%;
}
.loading_start .loading-bar .load-roll-cover .load-roll-content{
    position: relative;
    width: 0;
    height: 100%;
    background-color: #4caf50;
    animation: load 10s linear forwards;
}
.loading_start .loading-txt{
    position: absolute;
    bottom: 18%;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
}
@keyframes load {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  .blink-1{-webkit-animation:blink-1 1.5s infinite;animation:blink-1 1.5s infinite}
@-webkit-keyframes blink-1{0%,50%,100%{opacity:1}25%,75%{opacity:0}}@keyframes blink-1{0%,50%,100%{opacity:1}25%,75%{opacity:0}}