.bottom_bar{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 30px 30px 0px 0px;
    background: rgb(0 0 0 / 70%);
    background-blend-mode: overlay;
    backdrop-filter: blur(30px);
    padding: 12px 16px;
    z-index: 999;
}
.bottom_bar .bt-row{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 6px 0;
}
.bottom_bar .item-bar{
    flex: 0 0 20%;
    max-width: 19%;
    width: 100%;
}
.bottom_bar .item-bar .icon img{
    height: 24px;
    object-fit: contain;
}
.bottom_bar .item-bar .icon{
    margin-bottom: 4px;
}
.bottom_bar .item-bar .txt{
    text-align: center;
    font-size: 13px;
    color: #fff;
    font-family: "SFLight";
}
.bottom_bar .item-bar-center{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.bottom_bar .item-bar-center .icon-bar-center{
    position: absolute;
    width: 74px;
    height: 74px;
    left: 50%;
    top: -64px;
    transform: translateX(-50%);
}
.bottom_bar .item-bar-center .icon-bar-center-cover{
    position: relative;
}
.bottom_bar .item-bar-center .icon-bar-center-cover .ic-small-bar{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    transform: translate(-50%, -50%);
}
.bottom_bar .item-bar.item-bar-center .icon img{
    width: 100%;
    height: auto;
}